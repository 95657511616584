import { getUserStoreData } from 'store/user/accessors';
import { getUIStoreData } from 'store/uiEffects/accessors';
import { getAuthStoreData } from 'store/auth/accessors';

export const isPrivate = () => {
    const { isAuthenticated, isComplete } = getAuthStoreData();

    return isAuthenticated && isComplete;
};

export const isAppLibraryWebview = () => Boolean(getUIStoreData()?.isAppWebView);

export const isChallenge75 = () => Boolean(getUserStoreData()?.product_list?.challenge_75);

export const isFlatBelly = () =>
    Boolean(
        getUserStoreData()?.product_list?.workouts ||
            getUserStoreData()?.product_list?.fatburn ||
            getUserStoreData()?.product_list?.meal_fatburn
    );

export const isAppWebview = () => Boolean(getUIStoreData()?.isAppWebView || getUIStoreData()?.isWorkoutsAppWebView);
