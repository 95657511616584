import { selectCurrentUser, selectHasWorkouts } from 'store/user/selectors';

import { useSelector } from 'hooks';

import { getSideBarPrimaryLinks } from './getSideBarPrimaryLinks';

const usePrimaryLinks = () => {
    const isHideWorkouts = useSelector(selectHasWorkouts);

    const isHideChallenge = !!useSelector(selectCurrentUser).product_list?.challenge_75;

    const isHideFlatBelly = !!(
        useSelector(selectCurrentUser).product_list?.workouts ||
        useSelector(selectCurrentUser).product_list?.fatburn ||
        useSelector(selectCurrentUser).product_list?.meal_fatburn
    );

    const primarySideBarLinks = getSideBarPrimaryLinks({ isHideWorkouts, isHideChallenge, isHideFlatBelly });

    return {
        primarySideBarLinks,
        filteredPrimarySideBarLinks: primarySideBarLinks.filter(({ isShowItem = true }) => isShowItem),
    };
};

export default usePrimaryLinks;
